// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.
body {
    font-family: $bodycopy;
    font-weight: 300;
    font-style: normal;
    color: $black;
}

b, strong, p.bold {
    font-weight: 700;
}

p.italic, em, .italic {
    font-style: italic;
}

p {
     font-size: 16px;
    line-height: 21px;
}
.h1, .h2, .h3,
h1, h2, h3 {
    font-family: $headings;
    font-weight: 300;
    font-style: normal;
}
h1 {
    font-size: 60px;
}
h2 {
    font-size: 2.5rem;
}
h3 {
    font-size: 1.5rem;
}
h4, .h4 {
    font-size: 28px;
    font-weight: 300 !important;
    font-family: $bodycopy;
}

h5 {
    font-family: $bodycopy;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 14px;
    line-height: 18px;
}


nav {
    font-family: $bodycopy;
    text-transform: uppercase;
    &.navbar {
        padding: 40px;
        border-bottom: 0px !important;;
    }
    li.nav-item {
        padding: 0px 20px;
    }
    a.nav-link {
        color: $white;
        padding: 0px;
        font-weight: 700;
        letter-spacing: 2px;
        font-size: 14px;
    }
    a:hover {
        color: $white;
    }
    .navbar-brand {
        font-family: $bodycopy;
        padding: 0px 0px 0px 10px;
        font-weight: 700;
        font-size: 18px;
        font-style: normal;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $white;
        &:after {
            display: none;
        }
    }
}

a {
    color: #333333;
    &:hover {
        color: #999999;
    }
}

.accordion-panel-title,
a {
    display: inline;
    position: relative;
    &:after {
        background: $white;
        width: 0px;
        height: 1px;
        position:absolute;
        top: 20px;
        content:'';
        left: 0px;
        -webkit-transition: width 0.5s ease-out; /* Safari prior 6.1 */
        transition: width 0.5s ease-out;
    }
    
    &:hover:after {
        width: 100%;
    }
}
.accordion-panel-title:after{
    top: 30px;
}
.accordion-panel-title[aria-expanded="true"]:after, 
.accordion-panel-title:hover:after {
    width: calc(100% - 23px);
}

section.jarallax {
    border-bottom: 20px solid $white;
    border-right: 20px solid $white;
    border-left: 20px solid $white;
    &.first-slide {
        border-top: 20px solid $white;
    }
    h1 {
        margin-bottom: 20px;
    }
   
}
.moreinfo_ctn {
    .accordion-panel-title[aria-expanded="true"] > .icon {
        -webkit-transform: rotateZ(90deg);
        transform: rotateZ(90deg);
    }
    .accordion-panel-title[aria-expanded="true"] > *, 
    .accordion-panel-title:hover > * {
        color: $white;
    }
    .accordion-panel-title {
        display: inline-block; 
    
        .icon {
            height: 1.2rem;
            margin-top: -4px;
        }
    }
    .moreinfo-panel {
        margin-top: 20px;
        background:rgba(0, 0, 0, 0.3);
        .external-link {
            display: inline-block;
           .icon {
               width: 14px;
               height: 14px;
           }
           a:hover:after {
               width: calc(100% - 15px);
           }
        }
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -webkit-transform: translateY(0);
        transform:translateY(0);
  }
	40% {
    transform:translateY(-10px);
  }
	60% {
    transform:translateY(-5px);
  }
}



.downarrow {
    position:absolute;
    bottom: 20px;
    left: 50%;
    margin-left: -15px;
    animation: bounce 2s infinite;
    .icon {
        -webkit-transform: rotateZ(90deg);
        transform: rotateZ(90deg);
        width: 30px;
        height: 30px;
    }
    a:after {
        display: none;
    }
}


footer {
    background: #eeeeee;
    color: #000000;
   a:after {
       display:none;
   }
   .text-muted, a {
       color: #BBBBBB !important;
   }
   a:hover {
       color: #666666 !important;
   }
}

.btn-primary {
    background-color: $lgrey;
    border-color: $lgrey;
    &:hover, &:focus, &:active {
        background-color: #333333;
        border-color: #333333;
        
        &:after {
            display: none;
        }
    }
}


@include media-breakpoint-down(md) {
    h1 {
    font-size: 45px;
    }
    h4 {
        font-size: 22px;
    }
    section.jarallax {
        border-bottom: 10px solid $white;
        border-right: 10px solid $white;
        border-left: 10px solid $white;
        &.first-slide {
            border-top: 10px solid $white;
        }
    }
    nav.navbar {
        padding: 30px 20px 20px 20px;
        li.nav-item {
            padding: 0px 10px;
            a.nav-link {
                letter-spacing: 1px;
                font-size: 12px;
            }
        }
    }
}




@include media-breakpoint-down(xs) {
    h1 {
    font-size: 35px;
    }
    .jarallax .h4 {
        font-size: 20px;
    }
}
