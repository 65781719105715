// 
// 
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.


// Color Scheme
// Uncomment the variables below and change the hex values.

// $primary:      #000;
// $primary-2:     #000;
// $primary-3:     #000;

// Do not change or remove the $theme-color-scheme or $theme-colors maps below

$theme-color-scheme: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3
);

$theme-colors: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3,
);

$bodycopy:  mr-eaves-xl-modern, Helvetica, Arial, sans-serif;
$headings:  'ivymode', Helvetica, Arial, sans-serif;

$white:     #FFFFFF;
$black:     #000000;
$lgrey:     #CCCCCC;